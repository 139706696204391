
.navbar {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 70% 20%;
    padding-top: 25px;
    background-color: transparent;
 }

 .logo {
  position: relative;
  padding-left: 15%;
  justify-content: start ;
 }

  .navbarbtns {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-self: center;
    justify-self: end;
    font-family: Adobe Clean;
    font-size: calc(1rem + 0.3vw);
    font-style: normal;
  }

  .logbtn {
    position: relative;
    width: fit-content;
    padding: 12px 20px;

    font-family: Adobe Clean;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    font-size: 1vw;
    background: transparent;
    border: none;
    border-radius: 7px;
    color: black;
  }
  
  .logbtn_home {
    position: relative;
    width: fit-content;
    padding: 12px 20px;

    font-family: Adobe Clean;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    font-size: 1vw;
    background: transparent;
    border: none;
    border-radius: 7px;
    color: white;
  }

  .contactbtn {
    position: relative;
    width: fit-content;
    padding: 12px 25px;

    background: #37A190;
    border: none;
    border-radius: 30px;
    color: white;
    font-family: Adobe Clean;
    font-weight: 700;
    line-height: 20px;
    font-size: 1vw;
    letter-spacing: 0em;
    text-align: left;
  }

  .mobileview{
    display: none;
    z-index: 99;
  }
  
  @media screen and (max-width: 950px) {
      .navbarbtns{
        display: none;
      } 
      
      .mobileview{
        display: flex;
      }
  }