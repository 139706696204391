.bigcontainer{
  width: 100%;
  height: fit-content;
  padding-top: 4%;
  padding-bottom: 4%;
  
}
 .login__container{
  display: grid;
  width:100%;
  font-family: Adobe Clean;
  font-style: normal;
  
 } 
  .login {
  width: 80%;
  display: flex;
  flex-direction: column; 
  justify-self: center;
}   

.actions {
  text-align: center;
  width: 100%;
  padding-top: 4%;
  
}
.img_container {
  width: 70%;
  margin-left: 15%;
  margin-top: 10%;
}
.forget__pass{
  color: #979797;
  font-family: Adobe Clean;
  font-style: normal;
  font-size: calc(0.8rem + 0.3vw);
  font-weight: 400;
  line-height: 32px; 
   text-align: end;
  cursor: pointer;
  
   
}
 

.icon_container{
  width: 100%; 
  align-items: center;
  text-align: center;
}
.formm {
  display: flex !important;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  font-family: Adobe Clean;
  font-style: normal;
}

.formm label{
  width: fit-content;
  padding-left: 2.5%;
  margin-bottom: 5px;
  font-family: Adobe Clean;
  font-style: normal;
}

.inputss{
  position: relative;
  width: 100%;
  height: 7vh;
  border-radius: 30px;
 
  padding:0 2.5%;
  margin-bottom: 3%;
  margin-top: 0.5%;
  border: 1px solid #000;
}

.passwordinput{
  position: relative;
  width: 95%;
  height: 7vh;
  border-radius: 30px;
  
  padding:0 2.5%;
  margin-bottom: 3%;
  margin-top: 0.5%;
  border: 1px solid #000;
}

.invalid {
  color: red;
  cursor: pointer;
  text-decoration: underline;
  font-size: calc(0.8rem + 0.3vw);
  padding-left: 2.5%;
}
.buttons{
    width: 100%;
    height: 7vh;
    font-family: Adobe Clean;
    font-style: normal;
    border-radius: 30px !important;
    background-color: #37A190 !important;
    justify-content: center;
    align-items: center;
}


.tesstw{
  position: absolute;
 width: 50px;
 height: 50px;
 top: 30%;
 left: 30%;
  z-index: 999;
  background-color: #741188;
}
.request {
  color: #DB6A1B;
  font-family: Adobe Clean;
  font-style: normal;
  font-size: calc(0.8rem + 0.3vw);
  font-weight: 400;
  line-height: 32px;  
}
.terms_privacy{
  width: 60%;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 10%;
  font-size: 14px;
}
.terms {
  margin-left: 20%;
}
.privacy {
  width: fit-content;
  margin-left: 62%;
}
.title{
  color: #000;
  text-align: center;
  font-family: Adobe Clean;
  font-style: normal;
  font-size: calc(2rem + 1.6vw);
  
  padding: 4% 0;
}

.checkbox{ 
    text-align: start;
    color: #979797;
    font-family: Adobe Clean;
    font-style: normal;
    font-size: calc(0.8rem + 0.3vw);
    font-weight: 400;
    line-height: 32px; 
    padding-left: 2.5%;
     
}

@media screen and (max-width: 950px) {
  .formm {
    width: 90%;
    margin-left: 5%;
  }
.inputss{
  height: 5vh;
}

.passwordinput{
  height: 5vh;
}
}