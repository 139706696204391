.heading {
    position: relative;
    height: fit-content;
    width: 100%;
    left: 50% !important;
    transform: translate(-50%);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    font-family: 'Adobe Clean';
    font-style: normal;
}

.navbar {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 70% 20%;
    padding-top: 25px;
    font-family: 'Adobe Clean';
    font-style: normal;
 }

 .logo {
  position: relative;
  padding-left: 15%;
  justify-content: start ;
 }

  .navbarbtns {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-self: center;
    justify-self: end;
    font-family: 'Adobe Clean';
    font-style: normal;
  }

  .logbtn {
    position: relative;
    width: fit-content;
    padding: 12px 20px;

    font-family: Adobe Clean;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    font-size: 1vw;
    background: transparent;
    border: none;
    border-radius: 7px;
    color: white;
  }

  .contactbtn {
    position: relative;
    width: fit-content;
    padding: 12px 20px;

    background: #37A190;
    border: none;
    border-radius: 30px;
    color: white;
    font-family: Adobe Clean;
    font-weight: 700;
    line-height: 20px;
    font-size: 1vw;
    letter-spacing: 0em;
    text-align: left;
    
  }

  .head {
    position: relative;
    display: flex;
    padding-top: 1rem ;
    padding: 10%;
  }

  .image{
    position: absolute;
    right: 0;
    top: 0;
  }

  .leftgrp{
    position: absolute;
    left: 0;
    top: 15%;
  }

  .description {
    text-align: start;
    width: 50%;
    color: #000;
  }

  .description h1{
    font-size: calc(2rem + 1.6vw);
    line-height: 150%;
    font-weight: 100;
    padding-bottom: 1rem;
    text-align: start;
    font-family: Adobe Clean;
    font-style: normal;
  }

  .description p{
    font-size: calc(0.4rem + 1vw);
    line-height: 150%;
    font-weight: 200;
    padding-bottom: 1rem;
    text-align: start;
    font-family: Adobe Clean;
    font-style: normal;
  }

  
  .description button{
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
    border-radius: 30px;
    padding: 8px 40px;
    font-family: 'Adobe Clean';
    font-style: normal;
    font-weight: 400;
    font-size: 1vw;
  }

  
  .mobileview{
    display: none;
  }

  /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 950px) {
    .navbar {
            display: flex;
            flex-direction: row;
            column-gap: 25%;
    }
    
  .image{
    width: 50%;
  }

    .head {
        grid-template-columns: 100%;
    }
    .description {
        width: 80%;
        justify-self: center;
    }
    .navbarbtns{
      display: none;
    } 
    
    .mobileview{
      display: flex;
    }
}