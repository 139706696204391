.home {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
}
.topbar {
  
  color: #00000078;
}

.sidebar{
  background-color: rgba(55, 161, 144, 0.47);
  color: #000;
  height: 100%;
   
  
}
.sidebartop{
 
  background-color: rgba(55, 161, 144, 0.47) !important;
 padding-top: 15%;
  color: #000 !important;
  width: 100%;
}

.sidebartop1{
   
  background-color: rgba(55, 161, 144, 0.47);;
  padding-top: 70%;
  color: #000;
  

}
.sidebar a{
  color: #000;
}

.icons {
  color: #000;
}

.closeicon{
  background-color: #DB6A1B;
  color: white;
  border-radius: 100px;
}

.overlay {
  background-image: linear-gradient(to left,#DB6A1B  , #DB6A1B);
  border-radius: 18px;
  align-items: center !important;
  text-align: center;
  justify-content: center;
  justify-items: center;
  width: 70%;
  color: white;
}


.overlay .icons{
  color: white;
}


.overlay1 {
  background-image: linear-gradient(to left,#DB6A1B  , #DB6A1B);
  border-radius: 17px;
  align-items: center !important;
  text-align: center;
  justify-content: center;
  justify-items: center;
  width: 70%;
  color: white;
}
.overlay1 .icons{
  color: white;
}
 