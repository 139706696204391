.plans_container {
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5vh 0 0vh 0;
    margin-bottom: 5%;
  }
  
  .plans_content {
    width: 80%;
    margin-left: 10%;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #000;
    font-family: Adobe Clean;
    font-style: normal;
  }

  .title_container{
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 50%);
    text-align: start;
    
  }

  .text h1{
    font-size: calc(2rem + 1.6vw);
    line-height: 150%;
    font-weight: 200;
  }
  
  .text p{
    width: 80%;
    font-size: calc(0.7rem + 0.4vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 150%;
  }

  
  .btns {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-self: flex-end;
    justify-self: end;
    right: 0;
    padding-bottom: 3%;
    padding-right: 3%;
    font-family: Adobe Clean;
    font-style: normal;
  }

  .clickedbtn {
    position: relative;
    width: fit-content;
    padding: 12px 20px;

    background: transparent;
    border: none;
    border-radius: 7px;
    color: black;
  }

  .unclickedbtn {
    position: relative;
    width: fit-content;
    padding: 12px 20px;

    background: #37A190;
    border: none;
    border-radius: 30px;
    color: white;
  }

  .card_container{
    margin-top: 5vh;
    width: 100%;
  }

  @media screen and (max-width: 950px) {
    .title_container{
      grid-template-columns: 1fr;
      text-align: center;
    }

    .btns{
    align-self: center;
    justify-self: center;
    }
    
  .text p{
    width: 100%;
  }
  }