.main_container {
  position: relative;
  width: 600px;
  left: 50%;
  transform: translate(-50%);
}

.heading {
  position: relative;
  width: 100%;
  height: 200px !important;
  background: url(../../assets/imagemenu.png);
  background-repeat: no-repeat;
  background-size: cover;
}


.storeimages{
  display: flex;
  margin-left: 38%;
  margin-top: -10.5%;
  margin-bottom: -50px;
   
}

.switch{
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}


.logo_left {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: -50%;
  left: 50px;
}

.logo_middle {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  bottom: -25%;
  left: 50%;
  transform: translate(-50%);
}

.logo_right {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: -50%;
  right: -450px;
}

.logo_none{
  display: none;
}

.menu {
  position: absolute;
  width: 100%;
  display: inline;
  top: 180px;

  background-color: white;
  border-radius: 25px;
}

.menu_header {
  position: relative;
  padding-left: 3%;
}

.menu_location {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
   
}

.menu_wifi {
  position: relative;
  padding-left: 3%;
}

  
  .mySwiper {
    position: relative;
    height: 150px;
    
  }

.option {
  position: relative;
  display: grid;
  margin-left: 70px;
  width: calc(7rem + 0.8vw);
  height: 45px;
  border: 1px solid #37A19078;
  align-items: center;
  padding: 1px 1px;
  cursor: pointer;
  border-radius: 64.007px;
  background:  #37A19078;
 justify-content: center;
}

.option_active{
  position: relative;
  display: grid; 
  margin-left: 70px;
  width: calc(7rem + 0.8vw);
  height: 45px;
  border-radius: 50px;
  align-items: center;
  padding: 1px 1px;
  cursor: pointer;
  background-color: #37A19078;
  border: 5px solid #37A19078;
  justify-content: center;
}
.title{
  color: #FFF;
  font-family: Adobe Clean;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  
}

.option_imgs {
  position: relative;
  display: inline-block;
  align-items: center;
}

.meals {
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.meals_header {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  margin: auto;
  padding-top: 15px ;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(to top, rgba(253, 252, 252, 0), rgb(252, 251, 251)) !important;
  color: black;
  border-radius: 10px 10px 0px 0px;
}

.close {
  position: fixed;
  color: white;
  left: 7%;
  top: 10%;
}

.form {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
}

/* Style the search field */
form.form input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 90%;
  background: #f1f1f1;
  justify-self: center;
  border-radius: 15px 0px 0px 15px;
}

/* Style the submit button */
form.form button {
  float: left;
  width: 7%;
  padding: 5px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
  border-radius: 0px 15px 15px 0px;
}

form.form button:hover {
  background: #0b7dda;
}

/* Clear floats */
form.form::after {
  content: "";
  clear: both;
  display: table;
}

.meals img{
  position: relative;
  left: 50%;
  transform: translate(-50%);
}

.discreptionss{
  width: 100%;
  height: 50px;
  flex-shrink: 0;
  color: white;
  border-radius: 8px;
  background: rgba(55, 161, 144, 0.47);
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.25);
  
}


/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
  .main_container{
    width: 100%;
  }
  
}