.contact_con{
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10%;
}

.title{
    width: 70%;
    margin-left: 15%;
    font-size: calc(2rem + 1.6vw);
    font-weight: 100;
    text-align: center;
    margin-bottom: 5%;
    margin-top: 5%;
    font-family: Adobe Clean;
    font-style: normal;
}

.form{
    width: 70%;
    margin-left: 15%;
    text-align: start;
    display: flex;
    flex-direction: column;
    font-family: Adobe Clean;
    font-style: normal;
}

.form input{
    width :97.4% ;  
    height: 7vh;
    border-radius: 30px;
    padding-left: 2.5%;
    margin-bottom: 3%;
    border: 1px solid #000;
}

.form textarea{
    width :97.4% ; 
    border-radius: 30px;
    padding-left: 2.5%;
    padding-top: 2vh;
    margin-bottom: 3%;
    border: 1px solid #000;
}

.form label{
    width: fit-content;
    padding-left: 2.5%;
    margin-bottom: 5px;
    font-family: Adobe Clean;
    font-style: normal;
}

.form button{
    background: #37A190;
    width: 100% ;
    border-radius: 30px;
    text-align: center;
    padding: 3vh 1.5%;
    border: none;
    color: #fff;
    font-family: Adobe Clean;
    font-style: normal;
}

@media screen and (max-width: 950px) {
    .title{
    font-size: calc(1rem + 0.3vw);
    }
    
.form{
    width: 80%;
    margin-left: 10%;
}
.form input{  
    height: 5vh;
}
}