.home {
  width: 90%;
  max-width: 40rem;
  padding: 3rem;
  margin: 2rem auto;
  text-align: center;
}
 
.cssssss {
  background: rgba(55, 161, 144, 0.47) !important;
  color: white;
  z-index: 100s;

}

.sidebar{
   
  background: rgba(55, 161, 144, 0.47) !important;
  
  color: white;
  height: 100%;
}
.sidebartop{
   
  background: rgba(55, 161, 144, 0.47)  !important;
  color: white;
}

.closeicon{
  background-color: #DB6A1B;
  color: white;
  border-radius: 100px;
}

.sidebartop1{
  
  background: rgba(55, 161, 144, 0.47) !important;
  color: white;
}
.sidebar a{
  color: white;
}

.icons {
  color: white;
}

.overlay {
  background-image: linear-gradient(to left,#DB6A1B  , #DB6A1B);
   
}

.overlay1 {
  position: relative;
  width: 10px !important;
  left: 0%;
  background-color:#DB6A1B  ;
}