.itemsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 8%;
    margin-bottom: 3%;
}



.itemsHeading{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;
}


.tableHeading {
    background-color: #0A2632;
     
    color: white;
  }
.itemHead {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
     
}
.itemsTableContainer {
    width: 95%;
}
 .headingRow{
    border-radius: 20px;
    background: rgba(55, 161, 144, 0.47);
 }

.itemRow {
    background-color: #f8ffff;
    
}
.itemRow:hover {
    background: rgba(255, 107, 1, 0.527);
}

.addbtn{
    border-radius: 116px;
    background: rgba(55, 161, 144, 0.47);
    width: 213px;
    height: 47px;
    margin-left: 70%;
    border-color: transparent;
}


.btns{
    position: relative;
     width: 90%;
    display: flex;
    flex-direction: row;
    align-items: start;
    column-gap: 15px;
    margin-bottom: 30px;
    padding-top: 3%;
    left: 3%;
  }

  .btncancel {
    position: relative;
    padding: 8px;
    width: 20%;
    font-size: calc(0.8rem + 0.5vw);
    color: white;
    border: none;
    border-radius: 116px;
    background: rgba(55, 161, 144, 0.47);
  }

  .btncancel:hover{
    background-color: #37A190;
  }
  
  .btnsave {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    color: white;
    border: none;
    width: 20%;
    border-radius: 116px;
    background: rgba(55, 161, 144, 0.47);
  }

  .btnsave:hover{
    background-color: #37A190;
  }
  
  