.forminput {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 10px;
  color: white;
}

.forminput input{
  position: relative;
  width: 90%;
  left: 5%;
  border-radius: 116px;
  border: 2px solid rgba(55, 161, 144, 0.17);
  background: rgba(55, 161, 144, 0.46);
}

.forminput button{
  position: relative;
  margin-left: -12px;
  border-radius: 116px;
  border: 2px solid rgba(55, 161, 144, 0.17);
  background: rgba(55, 161, 144, 0.46);
}

.dropdown_search {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 90%;
  background: #f1f1f1;
  justify-self: center;
  border-radius: 15px 0px 0px 15px;
}
.default {
  border: solid 1px grey;
  color: grey;
}
.dropdown_input {
  width: 100%;
  display: block;
}

.dropdown_list ul {
  border: 1px solid gray;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 186px;
  max-height: 200px;
  overflow-y: scroll;
  border-top: none;
}
.dropdown_list li {
  list-style: none;
  padding: 5px;
  cursor: pointer;
}

.dropdown_list li:hover {
  background: rgba(0, 0, 0, 0.03);
  font-weight: bold;
}
.dropdown_wrapper {
  display: inline-block;
}

.dropdown_list li.no-result {
  color: grey;
}

.dropdown-list li.no-result:hover {
  font-weight: normal;
}
/* ss */