.trends_container {
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5vh 0 5vh 0;
  }
  
  .trends_content {
    width: 80%;
    margin-left: 10%;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #000;
    font-family: Adobe Clean;
    font-style: normal;
  }

  .title_container{
    display: flex;
    width: 100%;
    flex-direction: row;
    text-align: start;
    column-gap: 5%;
    /* padding-left: 2%; */
  }
  
  .title_container h1{
    width: 100%;
    font-size: calc(2rem + 1.6vw);
    line-height: 150%;
    font-weight: 200 !important;
  }
  
  .trends_content p{
    font-size: calc(0.7rem + 0.4vw);
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1.5rem; /* 21.42px */
  }
  
  .swiper{
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 5vh;
      justify-content: center;
      text-align: center;
      align-items: center;
  }

  .arrowDesktop{
    width: 100%;
    display: flex ;
    justify-content: flex-end;
    align-self: center;
    text-align: end;
  }

  @media screen and (max-width: 950px){
    .arrowDesktop{
        display: none;
    }

    .title_container{
      flex-direction: column;
      text-align: center;
    }
    
  .title_container h1{
    width: 100%;
    text-align: start;
    font-size: calc(1.7rem + 0.5vw);
  }
  }