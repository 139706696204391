.main_container {
    position: relative;
    width: 600px;
    left: 50%;
    transform: translate(-50%);
  }
  
  .heading {
    position: relative;
    width: 100%;
    height: 200px !important;
    background: url(../../../assets/imagemenu.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  
.logo_left {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: -50%;
  left: 50px;
}

.logo_middle {
  position: relative;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  bottom: -25%;
  left: 50%;
  transform: translate(-50%);
}

.logo_right {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: -50%;
  right: -450px;
}

.logo_none{
  display: none;
}

  .close {
    position: fixed;
    color: white;
    left: 7%;
    top: 10%;
  }
  
  .menu {
    position: absolute;
    width: 100%;
    display: inline;
    top: 180px;
  
    background-color: white;
    border-radius: 25px;
  }
  
  .menu_header {
    position: relative;
    padding-left: 3%;
  }
  
  
  .menu_location {
    position: relative;
    padding-left: 3%;
    display: flex;
    flex-direction: row;
    column-gap: 250px;
    margin-left: 10px;
     
  }
  
  
  .menu_wifi {
    position: relative;
    padding-left: 3%;
  }

  .meals_list {
    height: fit-content;
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 8px 5px 0px rgba(0, 0, 0, 0.33);
    border-radius: 20px;
}

  .meals_list img{
    width: 20%;
    height: 50%;
    border-radius: 20px;
    margin-left: 2%;
    margin-top: 8%;
  }

  .storeimages{
    display: flex;
    margin-left: 38%;
    margin-top: -20%;
    margin-bottom: -50px;
     
  }
  
  .switch{
    position: relative;
    justify-content: end;
    display: flex;
    flex-direction: row;
  }
  

  .meals_list_name{
    position: relative;
    text-align: start !important;
    font-size: calc(0.6rem + 0.5vw);
    color: black;
    margin-top: 10%;
    padding-left: 10px;
  }

  .meals_list_price {
    position: relative;
    text-align: start !important;
    font-size: calc(0.7rem + 0.5vw);
    color: peru;
    align-items: start;
    padding-top: 10%; 
    width: 50%;
  }

  .meals_list_content {
    position: relative;
    text-align: start !important;
    font-size: calc(0.3rem + 0.4vw);
    color: black;
    padding-left: 10px;
    width: 80%;
  }

  .meals_list_add{
    position: absolute;
    bottom: 5%;
    right: 5%;
    color: peru;
  }

  .meals_list_remove{
    position: absolute;
    bottom: 5%;
    right: 12%;
    color: peru;
   
  }


  
/* =========== MEDIA VIEW (SMALL DEVICES) =========== */
@media screen and (max-width: 600px) {
  .main_container{
    width: 100%;
  }
  
}