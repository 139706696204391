
.qrContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-top: 7.7%;
    margin-bottom: 3%;

}
.qrMenuContainer{
    width: 50%;
    align-items: center !important;
    margin-left: 20%;
    

}
.qrWifiContainer {
    width: 50%;
    align-items: center !important;
    margin-top: -2%;
}
.qrWifi{
    width: 25% !important;
    height: fit-content !important;
    margin-left: 7% !important;

}
.qrMenu {
    margin-left: 5%;
}
