.categoriesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 4.4%;
    margin-bottom: 3%;

}
.categoriesHeading{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);

}
.head {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
    border-right: solid 1px rgb(166, 160, 160) ;
}
.menugroupContainer {
    width: 95%;
}
.tableHeading {
    background-color: #0A2632;
 
    color: white;
  }
.headingRow {
    background-color: #006d6f;
    color: white !important;
}
.menuRow {
    background-color: #d4eded;
    
}
.menuRow:hover{
    background-color: #aed0d0;
}
.itemRow {
    background-color: #f8ffff;
    
    
}
.itemRow:hover {
    background-color: #c3dddd;
}