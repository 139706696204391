.container{
    background: #FFF;
    height: 100%;
    text-align: center;
    width: 100%;
}

.text{
    color: #37A190;
    font-family: Adobe Clean;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
     
}

.logo{
    width:50%;
    justify-content: center;
     
     
}