.card_container{
    width: fit-content;
    height: fit-content;
    background: red;
    font-family: Metropolis;
    /* font-size: calc(0.5rem + 0.3vw); */
    letter-spacing: 0.01rem;
    border-radius: 20px;
}

.card_img{
    position: relative;
    width: fit-content;
    height: fit-content;
    object-fit: cover;
    -webkit-transition: all 0.8s ease;
   -moz-transition: all 0.8s ease;
   transition: all 0.8s ease;
}