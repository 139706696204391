.RegisterContainer{
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 4%;
    padding-bottom: 4%;

}
.title{
    color: #000;
    text-align: center;
    font-family: Adobe Clean;
    font-size: calc(2rem + 1.6vw);
    font-style: normal;
    font-weight: 200;
    line-height: normal;
}
.SecondContainer{
    padding-top: 4%;
    position: relative;
    width: 50%;
    margin-left: 25%;  
}
.textfield{
    width: 97%;
    height: 7vh;
    flex-shrink: 0;
    border-radius: 116px;
    border: 1px solid #37A190;
    display: grid;
    justify-content: center;
    align-items: center;
    padding-left: 3.2%;
}
.button{
    width: 100%;
    height: 7vh;
    color: #37A190;
    font-family: Adobe Clean;
    font-size: calc(1rem + 0.3vw);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #000;
    border-radius: 40px;
    background-color: white ;
    cursor: pointer;
    padding: 1% 0 2vh 0;
    text-align: center;
    align-items: center;
}

.border{
    margin-top: 6%;
    width: 50%;
    height: 1px;
    background: #DB6A1B;
    margin-left: 1% ;
   
}

.label{
        width: fit-content;
        padding-left: 2.5%;
        margin-bottom: 5px;
        font-family: Adobe Clean;
        font-style: normal;
        margin-top: 3%;
}

.CreateAccbutton{
    color: #FFFFFF;
    height: 7vh;
    font-family: Adobe Clean;
    font-size: calc(0.8rem + 0.3vw);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
    background-color:  #37A190 ;
    cursor: pointer;
    width: 100%;
    flex-shrink: 0;
    border-radius: 116px;
    border: 1px solid #37A190;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #37A190;
    margin-top: 5%;
    }
 
.radiobtn{
    text-align: start;
    color: #979797;
    font-family: Adobe Clean;
    font-size: calc(0.8rem + 0.3vw);
    font-style: normal;
    font-weight: 400;
    line-height: 32px; 
    padding-top: 5%;
}

.googleimg{
    width: 2rem;
    height: 2rem;
    padding-Right:4%;
    margin-Bottom: -1%;
}


 @media screen and (max-width: 650px) {

    .title{
        font-size: 24px;
    }
    .textfield{
         
        height: 32px;
    }

.button{
    width: 100%;
    height: 5vh;
    font-size:10px;
}

.googleimg{
    width: 1rem;
    height: 2rem;
    padding-Right:4%;
    margin-Bottom: -4%;
}

.label{
     
    font-size: 10px;
}

.radiobtn{
    
    font-size: 10px;
}

.CreateAccbutton{

    height: 5vh;
}
 
.SecondContainer{
    padding-top: 4%;
    position: relative;
    width: 80%;
    margin-left: 10%;
    
}


} 

  @media screen and (max-width: 950px) {

    .title{
        font-size: 30px;
    }
    .textfield{
         
        height: 40px;
    }

.button{
     
    height: 4vh;
    font-size:15px;

}


.googleimg{
    width: 1rem;
    height: 2rem;
    padding-Right:2%;
   align-items: center;

}

.label{
     
    font-size: 10px;
}

.radiobtn{
    
    font-size: 10px;
}

.CreateAccbutton{

    height: 5vh;
}
 
.SecondContainer{
    padding-top: 4%;
    position: relative;
    width: 80%;
    margin-left: 10%;
    
}

.radiobtn{
     
    font-size: 15px;
     
}

} 