.footer_con{
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.footer_content{
    width: 80%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    font-family: Adobe Clean;
    font-style: normal;
}

.description{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.description p{
    width: 40%;
    z-index: 10;
    font-size: calc(2rem + 1.6vw);
}

.rotate{
    width: 14%;
    height: 100%;
    pointer-events: none;
    margin-left: 35%;
    align-self: center;
}

@media (prefers-reduced-motion: no-preference) {
    .rotate {
      animation: App-logo-spin 20s infinite linear;
    }
  }

  
@keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

.companyinfo{
    width: 80%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    display: grid;
    grid-template-columns: 40% 60%;
    
}

.copyright{
    z-index: 10;
    font-size: calc(0.4rem + 0.3vw);
}

.socials{
    display: flex;
    flex-direction: row;
    column-gap: 5%;
    text-align: end;
    align-items: center;
    justify-content: end;
    font-size: calc(0.4rem + 0.3vw);
}


@media screen and (max-width: 950px) {
  .description p{
  font-size: calc(0.8rem + 0.3vw);
  }
  
}