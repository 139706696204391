.form {
  position: relative;
  display: flex;
  width: 500px;
  flex-direction: row;
  left: 0%;
}

.form input{
  position: relative;
  width: 100%;
  border: 2px solid #006d6f ;
  border-radius: 10px 0px 0px 10px;
}

.form button{
  position: relative;
  color: white;
  background-color: #006d6f ;
  border: none;
  border-radius: 0px 10px 10px 0px;
}
.dropdown-search {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 90%;
  background: #f1f1f1;
  justify-self: center;
  border-radius: 15px 0px 0px 15px;
}
.default {
  border: solid 1px grey;
  color: grey;
}
.dropdown-input {
  width: 100%;
  display: block;
}

.dropdown-list ul {
  border: 1px solid gray;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 186px;
  max-height: 200px;
  overflow-y: scroll;
  border-top: none;
}
.dropdown-list li {
  list-style: none;
  padding: 5px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background: rgba(0, 0, 0, 0.03);
  font-weight: bold;
}
.dropdown-wrapper {
  display: inline-block;
}

.dropdown-list li.no-result {
  color: grey;
}

.dropdown-list li.no-result:hover {
  font-weight: normal;
}


