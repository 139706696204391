.clientsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 4.4%;
    margin-bottom: 3%;

}
.clientsHeading{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 2.7%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;
    margin-top: 1%;
     

}
.clientsHead {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
    border-right: solid 1px rgb(166, 160, 160) ;
}
.clientsTableContainer {
    width: 95%;
}

.headingRow{
    background-color: #37A19078 !important;
    font-weight: 500;
    font-size: 18px;
}

.tablehead {
    background-color: #37A19078;
    color: white;
}
.itemRow {
    background-color: #f8ffff;
}

.icons {
    color: white !important;
}

.itemRow:hover {
    background-color: #c3dddd;
}

.edit {
    background:#006d6f !important;
    width: 30px;
}

.freeze {
    background:red !important;
    width: 30px;
}