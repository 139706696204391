.card_con{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 20% 0% 8% 0%;
    box-shadow: 0px 5px 6px 5px rgb(136, 179, 176);
}

.card_con_mostpop{
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 20% 0% 8% 0%;
    box-shadow: 0px 5px 6px 5px rgb(136, 179, 176);
    background-color:#DB6A1B;
    color: #fff;
}

.card_content{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    text-align: start;
}

.card_content button{
    background-color: #37A190;
    border-radius: 30px;
    border: none;
    color: #fff;
    padding: 20px 10px;
    margin-top: 20%;
}

.mostpopular{
    width: fit-content;
    height: fit-content;
    background-color: #37A190;
    border-radius: 30px;
    border: none;
    color: #fff;
    text-align: center;
    padding: 0 3%;
    right: 30px;
    top: 20px;
    position: absolute;
}