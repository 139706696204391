.maincontainer{
    width:60%;
    height:fit-content;
    overflow: hidden;
    top: 50%;
    transform: translate(42%, 22%);
    align-items: center !important;

    background-color: white;
}
.heading {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: rgba(55, 161, 144, 0.47);
    color: white;
    border-radius: 10px;
    padding-left: 1rem;
    border-bottom: 0.1px solid #ccc ;
}

.mainheader{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 4%;
    font-size: calc(0.3rem + 0.8vw);
    gap: 1rem;

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.mainhead {
    font-size: calc(0.5rem + 1vw);
    font-weight: 700;
    padding-right: 1rem;
    border-right: solid 1px rgb(166, 160, 160) ;
}

.profilecontainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5%;
    padding-left: 8%;
 
}
.card1 {
    width: 95%;
    display: grid;
    
}
.inputs_container {
    display: grid;
    grid-template-columns: 80% ;
    gap: 2rem;
    padding: 2%;
    padding-left: 10%;
    padding-bottom: 3%;
    }
.btns{
    position: relative;
    width: 92%;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 30px;
    left: 3.7%;
    margin-bottom: 30px;
}

.btns_mini {
    display: none;
}

.btncancel {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color:#37A19078;
    color: white;
    border: none;
    border-radius: 10px;
}

.btnsave {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #37A19078;
    color: white;
    border: none;
    border-radius: 10px;
}

 /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
 @media screen and (max-width: 1240px) {
    .profilecontainer {
        grid-template-columns: 1fr;
    }

    .btns {
        display: none;
    }

    .btns_mini {
        position: relative;
    width: 90%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    left: 3.5%;
    margin-bottom: 30px;
    }

    .btnsave {
        margin-bottom: 20px;
    }
}