
.storeContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 7.7%;
    margin-bottom: 3%;

}
.heading {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 30% 20%;
    padding-left: 30px;
    height: 80px;
    border-radius: 28px;
    background: rgba(55, 161, 144, 0.47);
    color: white;
    border-bottom: 0.1px solid #ccc ;
}

    



.locationHeading{
    width: 100%;
    display: grid;
    background-color: #006d6f;
    border-radius: 10px;
    color: white;
    grid-template-columns: 5% 90% 5% ;
    border-bottom: 0.1px solid #ccc ;
}
.image_container{
    width: 95%;
    height: 350px;
    background-color: #ccc;
    border-radius: 10px;
}
.icon{
    padding: 1.7%;
    padding-right: 0.5%;
}
.addIcon{
    width: 100%;
    text-align: right;
    padding-top: 35%;
    padding-right: 10%;

}

.card1 {
    width: 100%;
    display: grid;
    margin-top: -30px;
    
}
.inputs_container {
display: grid;
grid-template-columns: 30% 30% 30% ;
gap: 2rem;
padding: 2%;
}

.textfieldss{
    border-radius: 116px;
    border: 2px solid #37A190;

}

.minimizewbtn{
    border: none;
    width: fit-content;
    height: 50px;
    margin-left: 140%;
    margin-top: 2%;
    color: white;
    background-color: transparent;
    
}


.inputs_location_container {
    display: grid;
    grid-template-columns: 45% 45% ;
    gap: 2rem;
    padding: 2%;
    }
.input_addres{
    grid-column: 1 / 3;
}

.logo_container{
position: relative;
 width: 150px;
 height: 150px;
 border-radius: 50%;
 background-color:antiquewhite;
bottom: -50%;
left: 2%;
}
.edit_icon{
    position: relative;
    bottom: -40%;
    left: 95%;
}
.sotialmedia_container{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 2rem;
    padding: 2%;
}

.btns_mini {
    display: none;
}
.btns{
    position: relative;
    width: 92%;
    display: flex;
    flex-direction: row;
    left: 5%;
    column-gap: 15px;
    margin-bottom: 30px;
    padding-top: 3%;
}
.btncancel {
    position: relative;
    padding: 8px;
    width: 20%;
    font-size: calc(0.8rem + 0.5vw);
    color: white;
    border: none;
    border-radius: 116px;
    background: rgba(55, 161, 144, 0.47);
}
.btncancel:hover{
    background-color: #37A190;
}

.btnsave {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    color: white;
    border: none;
    width: 20%;
    border-radius: 116px;
    background: rgba(55, 161, 144, 0.47);
}
.btnsave:hover{
    background-color: #37A190;
}


