.about_con{
    width: 100%;
    height: fit-content;
    padding: 0 0 5% 0%;
    justify-content: center;
    text-align: center;

}

.about{
    width:80%;
    height: fit-content;
    text-align: center;
    justify-content: center;
    margin-left: 10%;
    font-family: Adobe Clean;
    font-style: normal;
}

.about h1{
  font-size: calc(2rem + 1.6vw);
  line-height: 150%;
  font-weight: 200;
}

.about p{
    font-size:calc(0.7rem + 0.5vw);
    font-weight: 400;
}


  /* =========== VR SECTION =========== */

  .VR{
    width: 80%;
    margin-left: 10%;
    height: fit-content;
    display: grid;
    grid-template-columns : 33.3% 33.3% 33.3%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .description{
    text-align: start;
    padding-bottom: 8%;
    display: block  ;
    font-family: Adobe Clean;
    font-style: normal;
  }

  .description h1{
    font-size: calc(2rem + 1.6vw);
    line-height: 150%;
    font-weight: 300;
  }

  .download{
    flex-direction: column;
  }
  
  .download img{
    width: 60%;
    padding: 2% 0;
  }

  .descriptionmobile{
    display: none;
    width: 80%;
    margin-left: 10%;
    text-align: center;
  }
  .descriptionmobile h1{
    font-size: calc(2rem + 1.6vw);
    line-height: 150%;
    font-weight: 200;
  }

  @media screen and (max-width: 950px) {
    .VR{
    grid-template-columns :50% 50%;
    }

    .description{
      display: none;
    }

    
  .descriptionmobile{
    display: block  ;
  }
  }