.profileContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 4.4%;
    margin-bottom: 3%;
    
}

.profileheader{
    width: 100%;
    align-items: center;
     display: flex;
    flex-direction: row;
    padding-left: 10%;
    column-gap: 50%;
    font-size: calc(0.3rem + 0.8vw);
    padding-top: 7%;
    
}


.profilehead {
        font-size: calc(0.5rem + 1vw);
        font-weight: 700;
        padding-right: 2rem;
            }


.Passwordchange{
    width:15vw;
    text-decoration: none ;
    border-radius: 116px;
    background: rgba(55, 161, 144, 0.47);
    
  }
  .Passwordchange:hover{
    background: rgba(55, 161, 144, 0.47);
  }




.storecontainerr {
    position: relative;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2,50%);
  
    gap: 2rem;
}

.card1{
    position: relative;
    width:100%;
    display: flex;
    padding-top: 5%;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-top: -30px;
}

.heading{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 30% 20%;
    padding-left: 30px;
    height: 90px;
    border-radius: 28px;
    background: rgba(55, 161, 144, 0.47);
    color: white;
    border-bottom: 0.1px solid #ccc ;
     
     
}

 

.inputs_container{
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 42%);
    left: 30px;
    gap: 2rem;
    padding-bottom: 44px;
}

.subs_dates{
    position: relative;
     
    display: flex;
    flex-direction: row;
    
    column-gap: 30px;
    padding-bottom: 20px;


    background: transparent;
}

.inputs_container1{
    position: relative;
    width: 95%;
    display: grid;
    gap: 2.5rem;
    align-items: center;
    grid-template-columns: repeat(3, 30%);
   
}

.info{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
   
  
}

.inputext{
    width: 289px;
    height: 58px;
    flex-shrink: 0;
    border-radius: 116px;
    border: 2px solid #37A190;
    padding-left: 10%;
    



}
 
.inputs_container_location{
    position: relative;
    width: 95%;
    display: grid;
    grid-template-columns: repeat(3, 30%);
    gap: 2.5rem;
    margin-bottom: 2rem;
}

.checkbox {
    position: relative;
    display: flex;
    align-items: center !important;
}

.btns{
    position: relative;
    width: 95%;
    display: grid;
    grid-template-columns: 49% 49%;
    column-gap: 30px;
    margin-bottom: 30px;
}

.btns_mini {
    display: none;
}

.btncancel {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #04b5b8;
    color: white;
    border: none;
    border-radius: 10px;
}

.btnsave {
    position: relative;
    padding: 8px;
    font-size: calc(0.8rem + 0.5vw);
    background-color: #006d6f;
    color: white;
    border: none;
    border-radius: 10px;
}

 /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
 @media screen and (max-width: 1240px) {
    .storecontainer {
        grid-template-columns: 1fr;
    }

    .btns {
        display: none;
    }

    .btns_mini {
        position: relative;
    width: 95%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    }

    .btnsave {
        margin-bottom: 20px;
    }
}