footer {
    
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
    border-radius: 0px 0px 25px 25px;
}

footer a {
    color: #DB6A1B;
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.footer__socials {
    display: flex;
    justify-content: center;
 
    margin-bottom: 1rem;
    color: #DB6A1B;
    
}

.footer__socials a{
    background: var(--color-bg);
    color: #DB6A1B;
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footer__socials a:hover {
    background: transparent;
    border-color: var(--color-bg);
    color: #DB6A1B;
}

.footer__copyright {
    margin-bottom: 20%;
    color: white !important;
    display: flex;
    flex-direction: row;
}

 
    .logoplavi{
        width: 20%;
        margin: auto auto;
        
    }

    /* =========== MEDIA VIEW (SMALL DEVICES) =========== */
    @media screen and (max-width: 600px) {
        .permalinks {
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 1.5rem;
        }

        .footer__socials {
            margin-bottom: 2.6rem;
        }
    }